<template>
    <div>
        <el-dialog
            :title="
                `${JSON.stringify(currentRow) === '{}' ? '新增' : '编辑'}种子`
            "
            :visible.sync="visible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true"
            top="5vh"
            @close="close"
            center
        >
            <el-form
                :inline="false"
                :model="data"
                class="formData"
                ref="formData"
                :rules="rules"
                label-width="140px"
                v-loading="loading"
            >
                <el-form-item label="种子图片：" prop="picture">
                    <upload-images
                        :file-list.sync="data.picture"
                        width="100px"
                        isCropper
                        :canvas="editGoods"
                        :limit="1"
                    ></upload-images>
                </el-form-item>
                <el-form-item label="种子名称：" prop="name">
                    <el-input
                        v-model="data.name"
                        style="width:600px"
                        maxlength="8"
                        placeholder="请输入种子名称"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="种子编码：">
                    <el-input
                        v-model="data.code"
                        disabled
                        style="width:600px"
                        maxlength="8"
                        placeholder="请输入种子编码"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="每单位种植价格：" prop="price">
                    <el-input
                        style="width:600px"
                        placeholder="请输入每单位种子的种植价格"
                        v-model="data.price"
                        maxlength="64"
                        @input="numberFn('price')"
                    ></el-input>
                    元<span>&ensp;每单位种植面积：{{plantingArea}}㎡</span>
                </el-form-item>

                <el-form-item label="适合季节：" prop="matchSeason">
                    <el-checkbox-group v-model="data.matchSeason">
                        <el-checkbox label="春季">春季</el-checkbox>
                        <el-checkbox label="夏季">夏季</el-checkbox>
                        <el-checkbox label="秋季">秋季</el-checkbox>
                        <el-checkbox label="冬季">冬季</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="适合种植月份：" prop="matchMonth">
                    <el-checkbox-group v-model="data.matchMonth">
                        <span
                            v-for="(item, i) in 12"
                            :key="i"
                            style="margin-right: 5px;"
                        >
                            <el-checkbox :label="item"
                                >{{ item }}月</el-checkbox
                            >
                        </span>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="收获周期：" prop="harvestCycle">
                    <el-input
                        v-model="data.harvestCycle"
                        style="width:600px"
                        maxlength="12"
                        placeholder="如: 60-90天"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="平均产量：" prop="avgProduction">
                    <el-input
                        v-model="data.avgProduction"
                        style="width:600px"
                        maxlength="8"
                        placeholder="请输入每平米的平均产量"
                        @input="numberFn('avgProduction')"
                    />&ensp;kg/m²
                </el-form-item>
                <el-form-item label="每公斤回收价格：" prop="priceRecycle">
                    <el-input
                        v-model="data.priceRecycle"
                        style="width:600px"
                        maxlength="8"
                        placeholder="请输入每公斤回收价格"
                        @input="numberFn('priceRecycle')"
                    />&ensp;元/kg
                </el-form-item>

                <!-- 物流信息 -->
                <div class="edit-goods-module">
                    <div class="edit-goods-header">
                        <div class="title">物流信息</div>
                        <div class="content"></div>
                        <!-- <el-link class="right" type="primary" @click="showSelectPostage">修改运费模板
                        <el-icon class="el-icon-arrow-right"></el-icon>
                        </el-link> -->
                        <el-button
                            icon="el-icon-refresh-left"
                            type="primary"
                            plain
                            size="mini"
                            style="margin-right: 50px"
                            @click="refreshBtn"
                            >刷新运费模板数据</el-button
                        >
                        <el-link
                            class="right"
                            type="primary"
                            @click="goFreightTemplate"
                            >前往运费模板
                            <el-icon class="el-icon-arrow-right"></el-icon>
                        </el-link>
                    </div>
                    <div>
                        <el-row class="edit-mail-data-wrap">
                            <el-col :span="3">配送方式1</el-col>
                            <el-col :span="21">
                                <el-checkbox
                                    v-model="
                                        data.baseGoodsInfo.isSupportPostage
                                    "
                                    :true-label="1"
                                    :false-label="2"
                                    :disabled="true"
                                    >邮寄快递</el-checkbox
                                >
                                <el-row
                                    v-if="
                                        mailTemplateList &&
                                            mailTemplateList.length > 0
                                    "
                                    :span="20"
                                    class="edit-mail-data"
                                    type="flex"
                                >
                                    <el-col :span="3">运费模板:</el-col>
                                    <el-col :span="21">
                                        <el-select
                                            v-model="
                                                data.baseGoodsInfo
                                                    .freightTemplateId
                                            "
                                            placeholder="请选择"
                                            @change="selectMailTemplate"
                                            ref="selectLabel"
                                        >
                                            <el-option
                                                v-for="item in mailTemplateList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                                <el-row
                                    v-if="selectFreightTemplateData"
                                    :span="20"
                                    class="edit-mail-data"
                                    type="flex"
                                >
                                    <el-col :span="3">
                                        <span>模板信息:</span>
                                    </el-col>
                                    <el-col
                                        :span="21"
                                        style="
                                        background: #66b1ff29;
                                        padding: 0 10px;
                                        border-radius: 4px;
                                        "
                                    >
                                        <div style="padding: 5px 0">
                                            发货地：{{
                                                selectFreightTemplateData
                                                    ? JSON.parse(
                                                          selectFreightTemplateData.addrJson
                                                      ) &&
                                                      JSON.parse(
                                                          selectFreightTemplateData.addrJson
                                                      ).receiveAddressInfo
                                                    : ""
                                            }}
                                        </div>
                                        <div style="padding: 5px 0">
                                            运费：{{
                                                selectFreightTemplateData
                                                    ? selectFreightTemplateData.defaultFeeText
                                                    : ""
                                            }}
                                        </div>
                                        <div
                                            style="padding: 5px 0; max-height: 100px; overflow: auto"
                                        >
                                            暂不发货地：{{
                                                selectFreightTemplateData
                                                    ? getNonDeliveryArea(
                                                          selectFreightTemplateData.nonDeliveryArea
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <!-- <el-row v-if="selectFreightTemplateData" :span="20" class="edit-mail-data" type="flex">
                                    <el-col :span="3">
                                        <span v-if="selectFreightTemplateData.billingType == 2">物流重量:</span>
                                        <span v-if="selectFreightTemplateData.billingType == 3">物流体积:</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item v-if="selectFreightTemplateData.billingType == 2" prop="baseGoodsInfo.calculateWeight"
                                            :rules="formRules.postageNumber" class="customItemWith" style="margin: 0px;">
                                            <el-input placeholder="请输入重量" v-model="data.baseGoodsInfo.calculateWeight">
                                                <template slot="append">千克</template>
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item v-if="selectFreightTemplateData.billingType == 3" prop="baseGoodsInfo.calculateVolume"
                                            :rules="formRules.postageNumber" class="customItemWith" style="margin: 0px;">
                                            <el-input placeholder="请输入体积" v-model="data.baseGoodsInfo.calculateVolume">
                                                <template slot="append">立方米</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2" :offset="1">
                                        <el-tooltip class="item" effect="dark" content="当前运费模板，按物流重量（含包装）计费" placement="top-start"
                                            v-if="selectFreightTemplateData.billingType == 2">
                                            <i class="el-icon-info"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="当前运费模板，按物流体积（含包装）计费" placement="top-start"
                                            v-if="selectFreightTemplateData.billingType == 3">
                                            <i class="el-icon-info"></i>
                                        </el-tooltip>
                                    </el-col>
                                </el-row>
                                <el-row v-if="!selectFreightTemplateData">
                                    <el-col style="font-size: 14px; margin-top: 20px">未找到设为默认的邮寄快递模板，请前往设置！</el-col>
                                </el-row> -->
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import VideoLand from "@/components/supplier/editPostage/video-land.vue";
import uploadImages from "@/components/public/upload/uploadImages";
import newUploadImages from "@public/upload/newUploadImages";
import UploadVodVideo from "@/components/public/upload/uploadVodVideo.vue";
import UploadVideo from "@public/upload/uploadVideo";
import * as formRules from "@/assets/js/formRules";
import { editGoods } from "@/config/imageSize";
import { miniToolbarOptionsVideo } from "@/assets/js/utils";
import { returnBaseDataGoodsDetails } from "@/assets/js/modules/goods/goodsDetails";

import { URL } from "../../../../config/url.supplier";

export default {
    name: "addRendLand",
    props: {
        addDialogVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        uploadImages,
        newUploadImages,
        UploadVodVideo,
        VideoLand,
        UploadVideo
    },
    data() {
        return {
            visible: false,
            loading: false,
            formData: {},
            formRules,
            rules: {
                picture: [
                    {
                        required: true,
                        message: "请上传一张正方形图片",
                        trigger: "blur"
                    }
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                name: [
                    {
                        required: true,
                        message: "请输入种子名称",
                        trigger: "blur"
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入编码,切编码唯一,不可修改",
                        trigger: "blur"
                    }
                ],
                price: [
                    {
                        required: true,
                        message: "请输入每单位种子的种植价格",
                        trigger: "blur"
                    }
                ],
                matchSeason: [
                    {
                        type: "array",
                        required: true,
                        message: "请勾选",
                        trigger: "change"
                    }
                ],
                matchMonth: [
                    {
                        type: "array",
                        required: true,
                        message: "请勾选",
                        trigger: "change"
                    }
                ],
                harvestCycle: [
                    { required: true, message: "请输入", trigger: "blur" }
                ],
                avgProduction: [
                    { required: true, message: "请输入", trigger: "blur" }
                ],
                priceRecycle: [
                    { required: true, message: "请输入", trigger: "blur" }
                ]
            },
            value: "",
            editGoods,
            isVideoSnapshot: false,
            editorOption: {
                placeholder: "请在这里输入",
                theme: "snow",
                modules: {
                    toolbar: {
                        container: miniToolbarOptionsVideo, // 工具栏
                        handlers: {
                            image: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUp input")
                                        .click();
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                            video: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUpVideo input")
                                        .click();
                                } else {
                                    this.quill.format("video", false);
                                }
                            }
                        }
                    }
                },
                clipboard: {
                    matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]]
                }
            },
            iviewUpVideo: true,

            /** 基础数据结构 */
            data: {
                baseGoodsInfo: {
                    businessType: 1, // 商品类型-2批发、1普通
                    wholeSaleType: 1, // 规格类型-1-普通-2按数量-3按规格
                    supplierId: "", // 旗下村庄id
                    banners: "",
                    brandId: "",
                    brandLogo: "",
                    brandName: "",
                    brief: "",
                    calculateVolume: "", // 用于计算运费体积 ,
                    calculateWeight: "", // 用于计算运费重量 ,
                    cateDetails: "",
                    cover: "",
                    details: "",
                    detailsImages: "", // 图文详情-图片
                    detailsText: "", // 图文详情-文本
                    firstCateId: "",
                    freightTemplateId: "", // 运费模板ID ,
                    freightTemplateName: "",
                    id: "",
                    keywords: "",
                    marketPrice: 0,
                    name: "",
                    price: 0,
                    purchasePrice: 0,
                    salesNum: 0,
                    secCateId: "",
                    status: 0,
                    stockNum: 0,
                    supplierFirstCateId: 0,
                    supplierSecCateId: 0,
                    supportRefund: 1, // 是否支持退换货，1、是，2、否 ,
                    refundExplainImages: "", // 售后须知-图片
                    refundExplainText: "", // 售后须知-文本
                    thirdCateId: "",
                    type: 2,
                    video: "",
                    videoCover: "",
                    isSupportPostage: 1, // 该商品是否支持邮寄
                    isSupportSameCity: 2, // 该商品是否支持县域配送
                    isSupportSelfMention: 2, // 该商品是否支持自提
                    isPresell: 2, // 1、开启预告 2、关闭预告
                    presellTimeList: [], // 预告时间范围列表
                    wholeSaleRuleList: [
                        {
                            beginNum: 0,
                            price: 0
                        }
                    ] // 批发规则
                }, // 基本信息
                goodsAttributeList: [], // 非销售属性
                goodsSkuList: [], // sku 单项列表
                skuItemList: [], // sku 树形结构
                isOfficial: 1, // 前端自己添加的 1-正式的，提交审核以此判断是否携带id

                matchMonth: [],
                matchSeason: [],
                avgProduction: "", // 平均产量(kg/m2)
                code: "",
                harvestCycle: "", // 收获周期（天）
                name: "", // 种子名称
                picture: [], // 种子图片
                price: "", // 单位种植价格
                priceRecycle: "" // 每KG回收价格
            },
            plantingArea: "", // 每单位种植面积

            freightTemplateId: "", // 选中的邮寄运费模板id
            mailList: [], // //下拉邮寄运费模板列表(详细数据)
            mailTemplateList: [], // 下拉邮寄运费模板列表
            selectFreightTemplateData: {
                addrJson: null,
                defaultFeeText: "",
                nonDeliveryArea: "",
                billingType: ""
            }, // 选中的选中的邮寄运费模板详情
            sameCityContent: {}, // 县域配送数据
            selfMentionContent: {}, // 自提配送数据
            mailValue: false, // 是否启用邮寄
            cityValue: false, // 是否启用县域fffq
            pickUpValue: false // 是否启用自提
        };
    },
    created() {
        this.pageInit();
        if (this.isSupportWholesale == 2) {
            this.getVillageStores();
        }
        this.routingProcess();
        this.getlanddistrictList();
    },
    methods: {
        getlanddistrictList() {
            this.axios.get(URL.farm.farmLanddistrictList).then(res => {
                if (res.code === "0") {
                    this.plantingArea = res.data[0].plantMinArea;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        numberFn(p){
            this.data[p] = this.data[p]
            .replace(/[^\-\d.]/g, '') // 只能输入   数字 小数点 -
            .replace(/\-{2,}/g, "-") // 只能出现一次-
            .replace(/^0+(\d)/, '$1') // 如果第一位是 0 就替换成后面的数字
            .replace(/^\./, '0.') // 如果第一位是 . 就 替换成  0.
            .match(/^[\d\-]*(\.?\d{0,2})/g)[0] || '' // 开头只能允许数字或者 -
        },
        changeVodVideoFiles(videoFile) {
            this.formData.video = videoFile;
            this.$nextTick(() => {
                this.formData.video = videoFile;
            });
        },

        uploadSuccess(url, key) {
            if (key === "banner") {
                this.row.banner = url;
            } else if (key === "mainIcon") {
                this.cultureForm.mainIcon = url;
            } else if (key === "richText" && url) {
                const quill = this.$refs.myQuillEditor.quill;
                // 获取光标所在位置
                const length = quill.getSelection().index;
                quill.insertEmbed(length, "image", url);
                // 调整光标到最后
                quill.setSelection(length + 1);
            }
        },

        uploadSuccessVideo({ video }) {
            const quill = this.$refs.myQuillEditor.quill;
            const length = quill.getSelection().index;
            quill.insertEmbed(length, "video", video);
            quill.setSelection(length + 1);
            this.iviewUpVideo = false;
            this.$nextTick(() => {
                this.iviewUpVideo = true;
            });
        },

        close() {
            this.$emit("closeAdd");
            this.data = {
                baseGoodsInfo: {
                    businessType: 1, // 商品类型-2批发、1普通
                    wholeSaleType: 1, // 规格类型-1-普通-2按数量-3按规格
                    supplierId: "", // 旗下村庄id
                    banners: "",
                    brandId: "",
                    brandLogo: "",
                    brandName: "",
                    brief: "",
                    calculateVolume: "", // 用于计算运费体积 ,
                    calculateWeight: "", // 用于计算运费重量 ,
                    cateDetails: "",
                    cover: "",
                    details: "",
                    detailsImages: "", // 图文详情-图片
                    detailsText: "", // 图文详情-文本
                    firstCateId: "",
                    freightTemplateId: "", // 运费模板ID ,
                    freightTemplateName: "",
                    id: "",
                    keywords: "",
                    marketPrice: 0,
                    name: "",
                    price: 0,
                    purchasePrice: 0,
                    salesNum: 0,
                    secCateId: "",
                    status: 0,
                    stockNum: 0,
                    supplierFirstCateId: 0,
                    supplierSecCateId: 0,
                    supportRefund: 1, // 是否支持退换货，1、是，2、否 ,
                    refundExplainImages: "", // 售后须知-图片
                    refundExplainText: "", // 售后须知-文本
                    thirdCateId: "",
                    type: 2,
                    video: "",
                    videoCover: "",
                    isSupportPostage: 1, // 该商品是否支持邮寄
                    isSupportSameCity: 2, // 该商品是否支持县域配送
                    isSupportSelfMention: 2, // 该商品是否支持自提
                    isPresell: 2, // 1、开启预告 2、关闭预告
                    presellTimeList: [], // 预告时间范围列表
                    wholeSaleRuleList: [
                        {
                            beginNum: 0,
                            price: 0
                        }
                    ] // 批发规则
                }, // 基本信息
                goodsAttributeList: [], // 非销售属性
                goodsSkuList: [], // sku 单项列表
                skuItemList: [], // sku 树形结构
                isOfficial: 1, // 前端自己添加的 1-正式的，提交审核以此判断是否携带id

                matchMonth: [],
                matchSeason: [],
                avgProduction: "", // 平均产量(kg/m2)
                code: "",
                harvestCycle: "", // 收获周期（天）
                name: "", // 种子名称
                picture: [], // 种子图片
                price: "", // 单位种植价格
                priceRecycle: "" // 每KG回收价格
            }
        },

        routingProcess() {
            if (this.$route.params.data) {
                const { data } = this.$route.params;
                this.form = {
                    title: data.title,
                    cover: data.cover,
                    videoDetail: {
                        video: data.video,
                        videoHeight: data.videoHeight,
                        videoWidth: data.videoWidth
                    }
                };
                this.videoTypeValue = [
                    data.firstCategoryId,
                    data.lastCategoryId
                ];
                this.extendObj = {
                    extensionColumn: data.videoExtensionRspModelList
                };
                this.$refs.UploadVideo.setVideoUrl(data.video);
            }
        },

        // 页面初始话
        pageInit() {
            const params = this.$route.query;
            if (params.draftId) {
                this.isEdit = true;
            }
            this.originPageUrL = params.originPageUrL || "";
            // 新建进
            let refreshDraft = () => {
                if (!this.isInit) return false;
                this.isDraft = false;
                this.isOfficial = false;
                this.draftId = "";
                this.getEditDataSuccess(returnBaseDataGoodsDetails());
            };
            // 草稿进
            if (params.isDraft && params.draftId) {
                refreshDraft = () => {
                    if (this.draftId === params.draftId) return false;
                    this.isDraft = true;
                    this.isOfficial = false;
                    this.draftId = params.draftId;
                    this.getDraftGoodsDetails();
                };
            }
            // 编辑进
            if (params.isOfficial && params.draftId) {
                refreshDraft = () => {
                    if (this.draftId === params.draftId) return false;
                    this.isDraft = false;
                    this.isOfficial = true;
                    this.draftId = params.draftId;
                    this.getGoodsInfo();
                };
            }
            refreshDraft();
            this.isInit = true;
        },

        // 若当前登录的市镇级店铺，还需获取旗下的村店铺
        getVillageStores() {
            this.axios.get(URL.supplier.getVillageStores).then(res => {
                if (res.code == 0) {
                    this.supplierList = res.data;
                }
            });
        },

        // 获取邮寄快递的模板列表
        getList() {
            const data = {
                supplierId: this.data.baseGoodsInfo.supplierId
            };
            return new Promise((resolve, reject) => {
                this.axios
                    .get(URL.supplierPostage.list, { params: data })
                    .then(res => {
                        const list = [];
                        this.mailList = res.data;
                        if (res.data.length == 0) {
                            this.selectFreightTemplateData = null;
                        } else {
                            let haveDefault = true;
                            res.data.forEach((item, index) => {
                                list.push({
                                    value: item.id,
                                    label: item.name
                                });
                                if (this.data.baseGoodsInfo.freightTemplateId) {
                                    if (
                                        item.id ==
                                        this.data.baseGoodsInfo
                                            .freightTemplateId
                                    ) {
                                        this.freightTemplateId = item.id;
                                        this.selectMailTemplate(item.id);
                                        haveDefault = false;
                                    } else {
                                        if (haveDefault) {
                                            this.selectFreightTemplateData = null;
                                        }
                                    }
                                } else if (item.isDefault == 1) {
                                    this.freightTemplateId = item.id;
                                    this.selectMailTemplate(item.id);
                                    haveDefault = false;
                                } else {
                                    if (haveDefault) {
                                        this.selectFreightTemplateData = null;
                                    }
                                }
                            });
                        }
                        this.mailTemplateList = list;
                        resolve(res);
                    })
                    .catch(res => {})
                    .finally(res => {});
            });
        },

        // 获取县域配送板块数据
        getSameCityContent() {
            const data = {
                supplierId: this.data.baseGoodsInfo.supplierId
            };
            return new Promise((resolve, reject) => {
                this.axios
                    .get(URL.supplierPostage.getSameCityContent, {
                        params: data
                    })
                    .then(res => {
                        this.sameCityContent = res.data;
                        resolve(res);
                    })
                    .catch(res => {
                        this.sameCityContent = {};
                    })
                    .finally(res => {});
            });
        },
        // 获取自提板块数据
        getSelfMentionContent() {
            const data = {
                supplierId: this.data.baseGoodsInfo.supplierId
            };
            return new Promise((resolve, reject) => {
                this.axios
                    .get(URL.supplierPostage.getSelfMentionContent, {
                        params: data
                    })
                    .then(res => {
                        this.selfMentionContent = res.data;
                        resolve(res);
                    })
                    .catch(res => {
                        this.selfMentionContent = {};
                        // reject(res);
                    })
                    .finally(res => {});
            });
        },
        // 选择某个村店铺
        selectSupplier(data) {
            this.data.baseGoodsInfo.freightTemplateId = "";
            this.data.baseGoodsInfo.isSupportPostage = 2;
            this.data.baseGoodsInfo.isSupportSameCity = 2;
            this.data.baseGoodsInfo.isSupportSelfMention = 2;
            this.getList();
            this.getSameCityContent();
            this.getSelfMentionContent();
        },
        delNaN(item) {
            var arr = [];
            for (var i = 0; i < item.length; i++) {
                if (item[i] === item[i]) {
                    arr.push(item[i]);
                }
            }
            return arr;
        },
        // 选中某个邮寄快递模板
        selectMailTemplate(val) {
            let selectData = "";
            this.mailList.forEach(item => {
                if (item.id == val) {
                    selectData = item;
                    this.data.baseGoodsInfo.freightTemplateName = item.name;
                }
            });
            this.data.baseGoodsInfo.freightTemplateId = val;
            this.selectFreightTemplateData = selectData;
        },
        // 渲染暂不发货地区
        getNonDeliveryArea(val) {
            let list = JSON.parse(val || "[]");
            let text = "未配置暂不发货地区";
            if ((list || []).length > 0) {
                text = "";
                list.forEach(item1 => {
                    item1.cityList.forEach(item2 => {
                        item2.areaList.forEach(item3 => {
                            text =
                                text +
                                item1.province +
                                item2.city +
                                item3 +
                                "、";
                        });
                    });
                });
                text = text.slice(0, text.length - 1);
            }
            return text;
        },

        // 获取打开的配送方式
        getSupplierDelivery() {
            let self = this;
            self.axios
                .get(URL.supplierPostage.getSupplierDelivery)
                .then(res => {
                    self.mailValue = res.data.isOpenPostage == 2;
                    self.data.baseGoodsInfo.isSupportPostage =
                        res.data.isOpenPostage == 2
                            ? 2
                            : self.data.baseGoodsInfo.isSupportPostage;
                    self.cityValue = res.data.isOpenSameCity == 2;
                    self.data.baseGoodsInfo.isSupportSameCity =
                        res.data.isOpenSameCity == 2
                            ? 2
                            : self.data.baseGoodsInfo.isSupportSameCity;
                    self.pickUpValue = res.data.isOpenSelfMention == 2;
                    self.data.baseGoodsInfo.isSupportSelfMention =
                        res.data.isOpenSelfMention == 2
                            ? 2
                            : self.data.baseGoodsInfo.isSupportSelfMention;
                })
                .catch(res => {})
                .finally(res => {});
        },

        // 重置
        refreshBtn() {
            this.getList();
            this.getSameCityContent();
            this.getSelfMentionContent();
            this.getSupplierDelivery();
            this.$message({
                message: "刷新成功",
                type: "success"
            });
        },

        /**
         * 显示、隐藏‘选择运费模板’弹窗
         */
        showSelectPostage() {
            this.showDialogSelectPostage = true;
        },
        hideSelectPostage() {
            this.showDialogSelectPostage = false;
        },
        // 跳转运费模板
        goFreightTemplate() {
            let routeUrl = this.$router.resolve({
                path: "/newSupplierPostage"
            });
            window.open(routeUrl.href, "_blank");
        },
        seasonSort(season) {
            if (this.data.matchSeason.indexOf('春季') > -1) {
                season.push('1')
            }
            if (this.data.matchSeason.indexOf('夏季') > -1) {
                season.push('2')
            }
            if (this.data.matchSeason.indexOf('秋季') > -1) {
                season.push('3')
            }
            if (this.data.matchSeason.indexOf('冬季') > -1) {
                season.push('4')
            }
        },
        submit() {
            this.$refs.formData.validate(valid => {
                if (!valid) {
                    return;
                }
                let season = []
                this.seasonSort(season)
                const params = {
                    matchMonth: "," + this.data.matchMonth.join(",") + ",",
                    matchSeason: "," + season.join(",") + ",",
                    avgProduction: this.data.avgProduction, // 平均产量(kg/m2)
                    code: this.data.code,
                    deliveryTemplate: this.data.deliveryTemplate, // 物流模板
                    harvestCycle: this.data.harvestCycle, // 收获周期（天）
                    name: this.data.name, // 种子名称
                    picture: this.data.picture[0], // 种子图片
                    price: this.data.price, // 单位种植价格
                    priceRecycle: this.data.priceRecycle, // 每KG回收价格

                    deliveryTemplateId: this.data.baseGoodsInfo
                        .freightTemplateId,
                    deliveryTemplateName: this.$refs.selectLabel.selectedLabel,
                    id: this.data.id
                };

                let url =
                    JSON.stringify(this.currentRow) == "{}"
                        ? URL.farm.farmSeedSave
                        : URL.farm.farmSeedUpdate;
                this.axios[
                    JSON.stringify(this.currentRow) == "{}" ? "post" : "put"
                ](url, params).then(res => {
                    if (res.code === "0") {
                        this.close();
                        this.$message({
                            message: "操作成功",
                            type: "success"
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        }
    },

    watch: {
        currentRow: {
            handler: function(oldV, newV) {
                if (JSON.stringify(this.currentRow) != "{}") {
                    console.log(oldV, "66666666666");
                    let matchMonth = this.delNaN(
                        oldV.matchMonth.split(",").map(v => parseInt(v))
                    );
                    const {
                        name,
                        code,
                        avgProduction,
                        deliveryTemplate,
                        harvestCycle,
                        priceRecycle,
                        price,
                        deliveryTemplateId,
                        deliveryTemplateName,
                        id
                    } = oldV;
                    this.data.matchMonth = matchMonth;
                    this.data.matchSeason = oldV.matchSeason.split(",");
                    let season = []
                    if (this.data.matchSeason.indexOf('1') > -1) {
                        season.push('春季')
                    }
                    if (this.data.matchSeason.indexOf('2') > -1) {
                        season.push('夏季')
                    }
                    if (this.data.matchSeason.indexOf('3') > -1) {
                        season.push('秋季')
                    }
                    if (this.data.matchSeason.indexOf('4') > -1) {
                        season.push('冬季')
                    }
                    this.data.matchSeason = season
                    this.data.avgProduction = avgProduction; // 平均产量(kg/m2)
                    this.data.code = code;
                    this.data.deliveryTemplate = deliveryTemplate; // 物流模板
                    this.data.harvestCycle = harvestCycle; // 收获周期（天）
                    this.data.name = name; // 种子名称
                    this.data.picture = [oldV.picture]; // 种子图片
                    this.data.price = price; // 单位种植价格
                    this.data.priceRecycle = priceRecycle; // 每KG回收价格

                    this.data.deliveryTemplateId = deliveryTemplateId;
                    this.data.deliveryTemplateName = deliveryTemplateName;
                    this.data.baseGoodsInfo.freightTemplateId = deliveryTemplateId;

                    this.data.id = id;
                }
            }
        },
        addDialogVisible: function(val) {
            this.visible = val;
            this.getList();
        }
    },

    mounted() {
        // setTimeout(() => {
        // this.getList();
        // }, 2000);
        // this.getSameCityContent();
        // this.getSelfMentionContent();
        // this.getSupplierDelivery();
    },
    beforeDestroy() {
        this.data = {};
    }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/data";
@import (reference) "../../../../assets/css/defined";

.formData {
    padding: 0 20px;
    height: 72vh;
    overflow-x: hidden;

    h4 {
        text-align: center;
    }

    .quillBox {
        width: 600px;
        height: 400px;

        /deep/.ql-formats {
            line-height: 0;
        }

        /deep/.ql-toolbar {
            line-height: 0;
        }

        /deep/.viewOnly {
            height: 0px;
            overflow: hidden;
            border-top: 0;
            padding: 0px;
        }
    }

    .upload-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .marginL10 {
        margin-left: 10px;
    }

    h4 {
        margin: 15px 0;
    }
}

.avatar-uploader-quill {
    display: none;
}

.videoBox {
    position: relative;

    /deep/ .el-upload--picture-card {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-uploader-icon {
        position: absolute;
        top: 50%;
        left: 11%;
        margin-left: -35px;
        margin-top: -14px;
    }
}

.videoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
    z-index: 2009 !important;
}

/deep/ .el-form-item__content {
    min-width: 140px !important;
}

.quillBox {
    /deep/ .ql-container .ql-video {
        height: 220px;
    }
}

.edit-goods-module {
    border-radius: @border-radius-big;
    background-color: white;
    padding: @padding-primary;
    margin-bottom: @margin-primary;
}

.edit-mail-data-wrap {
    padding: 20px 10px;
    background: #f5f7fa;
    margin-top: 5px;
}

.edit-mail-data {
    margin-top: 10px;
    align-items: center;
    font-size: 14px;
}

.customItemWith {
    /deep/ .el-form-item__content {
        margin-left: 0px !important;
    }
}

.edit-goods-header {
    /*padding: @padding-primary 0;*/
    margin-bottom: @margin-primary;
    .defined-flex-item();

    .title {
        font-size: @font-size-heading;
        color: @font-color-heading-text;
        font-weight: bold;
    }

    .category-text {
        font-size: @font-size-main-text;
    }
}
</style>
