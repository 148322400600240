export function returnBaseDataGoodsDetails(){
  return {
    baseGoodsInfo:returnBaseDataGoodsDetailsBaseInfo(),// 基本信息
    goodsAttributeList:[], // 非销售属性
    goodsSkuList:[], // sku 单项列表
    skuItemList:[], // sku 树形结构
    isOfficial:1,// 前端自己添加的 1-正式的，提交审核以此判断是否携带id
  }
}
export function returnBaseDataGoodsDetailsBaseInfo(){
  // return goods.baseGoodsInfo
  return {
    businessType: 1, // 商品类型-2批发、1普通
    wholeSaleType: 1, // 规格类型-1-普通-2按数量-3按规格
    supplierId: '', // 旗下村庄id
    banners: '',
    brandId: '',
    brandLogo: '',
    brandName: '',
    brief: '',
    calculateVolume:'0', // 用于计算运费体积 ,
    calculateWeight:'0', // 用于计算运费重量 ,
    cateDetails: '',
    cover: '',
    details: '',
    detailsImages: '', // 图文详情-图片
    detailsText: '', // 图文详情-文本
    firstCateId: '',
    freightTemplateId:'', // 运费模板ID ,
    freightTemplateName:'',
    id: '',
    keywords: '',
    marketPrice: 0,
    name: '',
    price: 0,
    purchasePrice: 0,
    salesNum: 0,
    secCateId: '',
    status: 0,
    stockNum: 0,
    supplierFirstCateId: 0,
    supplierSecCateId: 0,
    supportRefund: 1, // 是否支持退换货，1、是，2、否 ,
    refundExplainImages:'', // 售后须知-图片
    refundExplainText:'', // 售后须知-文本
    thirdCateId: '',
    type: 2,
    video: '',
    videoCover:'',
    isSupportPostage: 2,// 该商品是否支持邮寄
    isSupportSameCity: 2, // 该商品是否支持县域配送
    isSupportSelfMention: 2,// 该商品是否支持自提
    isPresell: 2, // 1、开启预告 2、关闭预告
    presellTimeList: [], // 预告时间范围列表
    wholeSaleRuleList:[
      {
        beginNum:0,
        price:0
      }
    ], // 批发规则
  }
}

/**
 *  商品详情—— 已选择的非销售属性数据结构 */
export function returnBaseDataGoodsAttributeList(){
  return [
    returnBaseDataGoodsAttributeItem(),
  ]
}
export function returnBaseDataGoodsAttributeItem () {
  return {
    name:'',
    itemId:'',
    attrValList:[
      returnBaseDataGoodsAttributeChildrenItem(),
    ],
  }
}
export function returnBaseDataGoodsAttributeChildrenItem () {
  return {
    value: "",
    valueId: "",
  }
}

/**
 *  商品详情—— 已选择的  sku 树形结构 */
export function returnBaseDataGoodsDetailsSkuItemList () {
  return [
    // {
    //   skuItemId: 0,
    //   skuItemName: '',
    //   skuItemValList: [
    //     {
    //       skuItemValId: 0,
    //       skuItemValName: ''
    //     }
    //   ]
    // }
    returnBaseDataGoodsDetailsSkuItem(),
  ]
}
export function returnBaseDataGoodsDetailsSkuItem () {
  return {
    skuItemId: '',
    skuItemName: '',
    skuItemValList: [
      returnBaseDataGoodsDetailsSkuChildrenItem(),
    ]
  }
}
export function returnBaseDataGoodsDetailsSkuChildrenItem () {
  return {
    isSelect:true, // 前端编辑组件自用
    skuItemValId: '',
    skuItemValName: ''
  }
}

/**
 *  商品详情—— 已选择的  sku 树形结构 */
export function returnBaseDataGoodsDetailsGoodsSkuList () {
  return [
    // {
    //   barCode: '',
    //   cost: 0,
    //   cover: '',
    //   goodsId: 0,
    //   grossRate: 0,
    //   id: 0,
    //   marketPrice: 0,
    //   name: '',
    //   price: 0,
    //   purchasePrice: 0,
    //   salesNum: 0,
    //   status: 0,
    //   stockNum: 0
    // }
    this.returnBaseDataGoodsDetailsGoodsSkuItem()
  ]
}
export function returnBaseDataGoodsDetailsGoodsSkuItem () {
  return {
    isSelect:true, // 前端编辑组件自用
    barCode: '', //  条形码 ,
    cost: undefined, // 平台扣点金额 ,
    costPercent: undefined, // 平台扣点百分比 ,
    cover: '',
    goodsId: '',
    grossRate: undefined, // 毛利率 ,
    id: '',
    marketPrice: undefined, // 市场价 ,
    name: '',
    price: undefined, // 售价
    purchasePrice: undefined, // 供货成本价
    salesNum: undefined, // 销量 ,
    status: undefined, // 属性是否启用，1、启用，2、禁用 ,
    stockNum: undefined, // 库存,
    basicSales: 0, // 基础销量
    isEdit: true, // 判断是否保存

    price: true, // 
    grossProfitRate: undefined, // 
    operationCost: undefined,// 
    circulationCost: undefined, // 
    basePurchasePrice: undefined, // 

  }
}
